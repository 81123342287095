import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/Shell'

const BannerWrapper = styled.div`
  position: relative;
  @media (min-width: 992px) {
    height: 600px;
  }
`

const Image = styled(Plaatjie)`
  height: 100%;
  top: 0;
  right: 0;
  position: absolute !important;

  @media (min-width: 1850px) {
    max-width: 2000px;
  }
  @media (max-width: 1849px) {
    max-width: 1000px;
  }
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.light};
  }

  &:after {
    content: '';
    position: absolute;
    right: -220px;
    top: 0px;
    bottom: 0px;
    width: 220px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5648634453781513) 29%,
      rgba(255, 255, 255, 1) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  @media (min-width: 768px) {
    background-color: ${({ theme }) => theme.color.light};
  }
  @media (max-width: 767px) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 38%,
      rgba(255, 255, 255, 0.5032387955182073) 100%
    );
  }
  @media (max-width: 991px) {
    padding-top: 2rem;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.dark};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primary};

    @media (min-width: 992px) {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 2.5rem;
    }
    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 1.5rem;
    }

    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }

  & p {
    font-weight: ${({ theme }) => theme.font.weight.medium};
    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const BannerLanding: React.FC<BannerProps> = ({ fields }) => (
  <section>
    <BannerWrapper>
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-md-7 order-md-2">
            <Image loading="eager" alt="banner" image={fields?.image} />
          </div>
          <ContentWrapper className="col-md-5 position-relative h-100 d-flex align-items-center pt-5">
            <Content content={fields.description || ''} />
          </ContentWrapper>
        </div>
      </div>
    </BannerWrapper>
  </section>
)

export default BannerLanding
